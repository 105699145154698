<template>
   <div>
       <div id="allmap" :style="{width:width,height:height}"></div>
   </div>
</template>

<script>
export default {
   data() {
       return {};
   },
    props:{
        width:{
            type: String,
            default: '1200px'
        },
        height:{
            type: String,
            default: '460px'
        },
        lng:{
            type: Number | String,
            default:0
        },
        lat:{
            type: Number | String,
            default:0
        }
    },

   mounted () {
       this.mapInit()
   },

    methods: {
        mapInit(){
            var map = new BMap.Map("allmap");
            var point = new BMap.Point(this.lng, this.lat);
            map.centerAndZoom(point, 20);
            var myIcon = new BMap.Icon(require('@/assets/images/auout-mark.png'), new BMap.Size(60, 67), {
                anchor: new BMap.Size(30, 80)
            });
            var marker = new BMap.Marker(point, {
                icon: myIcon
            });
            map.addOverlay(marker);
            map.panBy(100, 100);
            map.enableScrollWheelZoom();
        }
    }
}
</script>

<style lang='scss' scoped>

</style>