<template>
    <div class="aboutUs">
        <div class="product_img">
            <img class="" :src="backImg" alt="">
        </div>
        <div class="main">
            <div class="company">
                <div class="company_title">
                    <p>武汉世纪星辰</p>
                    <div class="line"></div>
                </div>
                <div class="company_content">{{company}}</div>
            </div>
            <div class="contact">
                <div class="contact_item">
                    <div class="icon phone"></div>
                    <div class="contactUs">给我们打电话</div>
                    <div class="cway_txt">159 7145 2931</div>
                </div>
                <div class="contact_item">
                    <div class="icon QQ"></div>
                    <div class="contactUs">QQ联系我们</div>
                    <div class="cway_txt">792859496</div>
                </div>
                <div class="contact_item">
                    <div class="icon emial"></div>
                    <div class="contactUs">邮箱联系我们</div>
                    <div class="cway_txt">15971452931@163.com</div>
                </div>
            </div>
        </div>
        <div class="wechatBox">
            <div class="WeChat" :style="XZX"></div>
            <div class="wrap">
                <div class="text">手机扫一扫，添加微信</div>
                <img class="images" :src="erweima" alt="">
            </div>
        </div>
        <div class="map">
            <mapchina :lng="lng" :lat="lat" :width="width"></mapchina>
            <div class="adress">湖北省武汉市洪山区光谷创业街特1栋1单元2201室</div>
        </div>
        
   </div>
</template>

<script>
import Mapchina from './map.vue'
export default {
    components:{ Mapchina },
    data() {
        return {
            backImg: require('@/assets/images/banner/aboutUs.jpg'),
            erweima: require('@/assets/images/erweima.jpg'),
            XZX:{backgroundImage:"url("+require('@/assets/images/XZX.jpg')+")"},
            width:'100%',
            company:'武汉世纪星辰科技有限公司是一家专业的实验室仪器供应商。公司所经营的产品范围主要为生命科学、物理化学、食品卫生、环境环保、农林畜牧等科学研究领域实验室设备。公司凭借雄厚的资金实力、高素质的销售团队和专业技术人才，丰富的仪器销售和售后服务经验，现已逐渐发展成为行业中较为知名的公司。公司秉承“顾客至上，锐意进取”的经营理念，坚持“客户第一”的原则为广大客户提供优质的服务。欢迎惠顾！产品涵盖实验室通用仪器，化学分析，物理测试等仪器，如天平、滴定仪、水分仪、粘度计、搅拌器、折光仪、烘箱、培养箱、水槽、洁净工作台、显微镜、分光光度计、酸度计、色谱等，涉及到石化、精细化工、生物制药、日化、食品、涂料、建材、电子、农业等众多生产行业，以及高校、研究所等科研机构的实验室。 公司一直以来，与国内外众多的知名品牌的实验室仪器制造商保持着紧密的合作关系。一直致力于把国内外最先进的仪器设备推荐给广大的科研教学工作者。公司员工全部拥有本科以上学历，销售和技术人员都拥有较强的专业背景，能为客户提供全面、优质的实验室全套解决方案：提供售前技术咨询和快速供货服务，同时还提供包括安装调试、使用指导、应用指导、维护保养在内的整套售后服务',
            lng:114.424544,
            lat:30.505874,
        };
    }
}
</script>

<style lang='scss' scoped>
.main{
    width:1200px;
    margin:0 auto;
}
.company{
    margin-bottom: 40px;
    .company_content{
        margin: 0px auto;
        line-height: 30px;
        font-size: 16px;
        color: #333;
        margin-top: 30px;
        text-indent: 2em;
    }
}
.contact{
    display: flex;
    justify-content: space-between;
    .contact_item{
        height:420px;
        width:100%;
        padding-top:40px;
        border-left:1px solid #E9E9E9;
        text-align: center;
        transition:  all 1s linear;
        &:nth-child(3){
            border-right:1px solid #E9E9E9;
        }
        .phone{
            background-position: 1px -263px;
        }
        .QQ{
            background-position: -120px -263px;
        }
        .emial{
            background-position: -240px -263px;
        }
        &:hover .cway_txt{
            color:#627afd;
        }
        &:hover .phone{
            background-position: 1px -388px;
        }
        &:hover .QQ{
            background-position: -127px -394px;
        }
        &:hover .emial{
            background-position: -248px -391px;
        }
        .icon{
            width: 128px;
            height: 128px;
            background-color: #f8f8f8;
            background-image: url('../../assets/images/icon/yxIcon.png');
            border-radius: 64px;
            transition: all 0.3s;
            margin:0 auto 20px;
        }
        .contactUs{
            font-size: 16px;
            color: #666;
            line-height: 30px;
            text-align: center;
            font-weight: 100;
            margin-bottom: 10px;
        }
        .cway_txt{
            font-size: 30px;
            text-align: center;
            line-height: 50px;
            color: #121212;
        }
    }
}
.wechatBox{
    overflow: hidden;
    position: relative;
    .wrap{
        margin:0 auto;
        height:270px;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        .text{
            margin: 0 auto 30px;
            font-size: 32px;
            width: 100%;
            line-height: 1.75;
            text-align: center;
            color: #FFF;
            font-weight: normal;
        }
        .images{
            margin-left:50px;
            width: 240px;
        }
    }
}
.WeChat{
    padding:80px 0;
    width:100%;
    height:300px;
    // background: url('../../assets/images/XZX.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    filter: blur(4px);
    
}
.map{
    position: relative;
    width:100%;
    margin:40px auto;
    .adress{
        position:absolute;
        left:0;
        bottom: -40px;
        width: 100%;
        height:40px;
        line-height: 40px;
        background: #0F162BFF;
        color:#fff;
        text-align: center;
    }
}
</style>